import { lazy } from 'react';

export const PageRoutes = {
    MainPage: {
        route: '/en',
        name: 'main',
        Component: lazy(() => import('../pages/Main/Main')),
        options: {},
        routeProps: {
            exact: true
        },
    },
    Details: {
        route: '/shops/details/:id',
        name: 'details',
        Component: lazy(() => import('../pages/Details/Details')),
        options: {},
        routeProps: {
            exact: true
        },
    },
    PageNotFound: {
        name: 'pageNotFound',
        Component: lazy(() => import('../pages/PageNotFound/PageNotFound')),
        options: {},
    },
};
