import { Link } from 'react-router-dom'
import Logo from '../../assets/kkk.png'
import st from './Footer.module.scss';

const Footer = () => {
    return (
        <div className={st.footerContainer}>
            <div className={st.footer}>
                <div className={st.footerTexts}>
                    <div className={st.allRights}>© {new Date().getFullYear()} All rights reserved</div>
                    <div className={st.createdBy}>
                        <div className={st.span}>Created by Mams0n</div>
                    </div>
                </div >
                <div className={st.logo}><Link to='/en'><img onClick={() => window.scrollTo(0, 0)} src={Logo} alt='' /></Link></div>
            </div>
        </div>
    )
}

export default Footer