import Sportwear from '../../src/assets/Sportwear.png'
import Lingerie from '../../src/assets/Lingerie.png'
import Gift from '../../src/assets/Gift.png'
import Gift2 from '../../src/assets/Gift2.png'
import Men from '../../src/assets/Men.png'
import Women from '../../src/assets/Women.png'
import Jewellery from '../../src/assets/Jewellery.png'
import SecondHand from '../../src/assets/SecondHand.png'
import Bags from '../../src/assets/Bags.png'
import Kids from '../../src/assets/Kids.png'
import Shoes from '../../src/assets/Shoes.png'
import Arrival from '../../src/assets/Arrival.png'
import Summer from '../../src/assets/Summer.png'
import Watch from '../../src/assets/Watch.png'
import React from 'react'

export const Categories = [
  {
    id: 1,
    name: 'კაცი / Male',
    img: Men,
    count: '117',
    shops: [
      {
        shop_name: 'Nike',
        shop_id: '1',
        shop_url:
          'https://media.istockphoto.com/photos/string-light-bulbs-at-sunset-picture-id1300384615?k=20&m=1300384615&s=170667a&w=0&h=w-Su3d7pPUcjMVvFz2b_tYhs3SGtsSbjaTUzlooEByw=',
      },
      {
        shop_name: 'Adidas',
        shop_id: '2',
        shop_url:
          'https://blog-assets.shawacademy.com/uploads/2015/05/Importance-of-light-in-Photography-1.jpg',
      },
      {
        shop_name: 'Puma',
        shop_id: '3',
        shop_url:
          'https://images.pexels.com/photos/1124960/pexels-photo-1124960.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
      },
      {
        shop_name: 'Under Armour',
        shop_id: '4',
        shop_url:
          'https://denverzoo.org/wp-content/uploads/2018/10/zoolights5.jpg',
      },
      {
        shop_name: 'Nike',
        shop_id: '1',
        shop_url:
          'https://media.istockphoto.com/photos/string-light-bulbs-at-sunset-picture-id1300384615?k=20&m=1300384615&s=170667a&w=0&h=w-Su3d7pPUcjMVvFz2b_tYhs3SGtsSbjaTUzlooEByw=',
      },
      {
        shop_name: 'Adidas',
        shop_id: '2',
        shop_url:
          'https://blog-assets.shawacademy.com/uploads/2015/05/Importance-of-light-in-Photography-1.jpg',
      },
      {
        shop_name: 'Puma',
        shop_id: '3',
        shop_url:
          'https://images.pexels.com/photos/1124960/pexels-photo-1124960.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
      },
      {
        shop_name: 'Nike',
        shop_id: '1',
        shop_url:
          'https://media.istockphoto.com/photos/string-light-bulbs-at-sunset-picture-id1300384615?k=20&m=1300384615&s=170667a&w=0&h=w-Su3d7pPUcjMVvFz2b_tYhs3SGtsSbjaTUzlooEByw=',
      },
      {
        shop_name: 'Adidas',
        shop_id: '2',
        shop_url:
          'https://blog-assets.shawacademy.com/uploads/2015/05/Importance-of-light-in-Photography-1.jpg',
      },
      {
        shop_name: 'Puma',
        shop_id: '3',
        shop_url:
          'https://images.pexels.com/photos/1124960/pexels-photo-1124960.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
      },
      {
        shop_name: 'Under Armour',
        shop_id: '4',
        shop_url:
          'https://denverzoo.org/wp-content/uploads/2018/10/zoolights5.jpg',
      },
      {
        shop_name: 'Nike',
        shop_id: '1',
        shop_url:
          'https://media.istockphoto.com/photos/string-light-bulbs-at-sunset-picture-id1300384615?k=20&m=1300384615&s=170667a&w=0&h=w-Su3d7pPUcjMVvFz2b_tYhs3SGtsSbjaTUzlooEByw=',
      },
      {
        shop_name: 'Adidas',
        shop_id: '2',
        shop_url:
          'https://blog-assets.shawacademy.com/uploads/2015/05/Importance-of-light-in-Photography-1.jpg',
      },
      {
        shop_name: 'Puma',
        shop_id: '3',
        shop_url:
          'https://images.pexels.com/photos/1124960/pexels-photo-1124960.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
      },
      {
        shop_name: 'Nike',
        shop_id: '1',
        shop_url:
          'https://media.istockphoto.com/photos/string-light-bulbs-at-sunset-picture-id1300384615?k=20&m=1300384615&s=170667a&w=0&h=w-Su3d7pPUcjMVvFz2b_tYhs3SGtsSbjaTUzlooEByw=',
      },
      {
        shop_name: 'Adidas',
        shop_id: '2',
        shop_url:
          'https://blog-assets.shawacademy.com/uploads/2015/05/Importance-of-light-in-Photography-1.jpg',
      },
      {
        shop_name: 'Puma',
        shop_id: '3',
        shop_url:
          'https://images.pexels.com/photos/1124960/pexels-photo-1124960.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
      },
      {
        shop_name: 'Under Armour',
        shop_id: '4',
        shop_url:
          'https://denverzoo.org/wp-content/uploads/2018/10/zoolights5.jpg',
      },
      {
        shop_name: 'Nike',
        shop_id: '1',
        shop_url:
          'https://media.istockphoto.com/photos/string-light-bulbs-at-sunset-picture-id1300384615?k=20&m=1300384615&s=170667a&w=0&h=w-Su3d7pPUcjMVvFz2b_tYhs3SGtsSbjaTUzlooEByw=',
      },
      {
        shop_name: 'Adidas',
        shop_id: '2',
        shop_url:
          'https://blog-assets.shawacademy.com/uploads/2015/05/Importance-of-light-in-Photography-1.jpg',
      },
      {
        shop_name: 'Puma',
        shop_id: '3',
        shop_url:
          'https://images.pexels.com/photos/1124960/pexels-photo-1124960.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
      },
      {
        shop_name: 'Nike',
        shop_id: '1',
        shop_url:
          'https://media.istockphoto.com/photos/string-light-bulbs-at-sunset-picture-id1300384615?k=20&m=1300384615&s=170667a&w=0&h=w-Su3d7pPUcjMVvFz2b_tYhs3SGtsSbjaTUzlooEByw=',
      },
      {
        shop_name: 'Adidas',
        shop_id: '2',
        shop_url:
          'https://blog-assets.shawacademy.com/uploads/2015/05/Importance-of-light-in-Photography-1.jpg',
      },
      {
        shop_name: 'Puma',
        shop_id: '3',
        shop_url:
          'https://images.pexels.com/photos/1124960/pexels-photo-1124960.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
      },
      {
        shop_name: 'Under Armour',
        shop_id: '4',
        shop_url:
          'https://denverzoo.org/wp-content/uploads/2018/10/zoolights5.jpg',
      },
      {
        shop_name: 'Nike',
        shop_id: '1',
        shop_url:
          'https://media.istockphoto.com/photos/string-light-bulbs-at-sunset-picture-id1300384615?k=20&m=1300384615&s=170667a&w=0&h=w-Su3d7pPUcjMVvFz2b_tYhs3SGtsSbjaTUzlooEByw=',
      },
      {
        shop_name: 'Adidas',
        shop_id: '2',
        shop_url:
          'https://blog-assets.shawacademy.com/uploads/2015/05/Importance-of-light-in-Photography-1.jpg',
      },
      {
        shop_name: 'Puma',
        shop_id: '3',
        shop_url:
          'https://images.pexels.com/photos/1124960/pexels-photo-1124960.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
      },
    ],
  },
  {
    id: 2,
    name: 'ქალი / Female',
    img: Women,
    count: '223',
    shops: [
      {
        shop_name: 'Nike',
        shop_id: '1',
        shop_url:
          'https://media.istockphoto.com/photos/string-light-bulbs-at-sunset-picture-id1300384615?k=20&m=1300384615&s=170667a&w=0&h=w-Su3d7pPUcjMVvFz2b_tYhs3SGtsSbjaTUzlooEByw=',
      },
      {
        shop_name: 'Adidas',
        shop_id: '2',
        shop_url:
          'https://blog-assets.shawacademy.com/uploads/2015/05/Importance-of-light-in-Photography-1.jpg',
      },
      {
        shop_name: 'Puma',
        shop_id: '3',
        shop_url:
          'https://images.pexels.com/photos/1124960/pexels-photo-1124960.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
      },
      {
        shop_name: 'Under Armour',
        shop_id: '4',
        shop_url:
          'https://denverzoo.org/wp-content/uploads/2018/10/zoolights5.jpg',
      },
      {
        shop_name: 'Nike',
        shop_id: '1',
        shop_url:
          'https://media.istockphoto.com/photos/string-light-bulbs-at-sunset-picture-id1300384615?k=20&m=1300384615&s=170667a&w=0&h=w-Su3d7pPUcjMVvFz2b_tYhs3SGtsSbjaTUzlooEByw=',
      },
      {
        shop_name: 'Adidas',
        shop_id: '2',
        shop_url:
          'https://blog-assets.shawacademy.com/uploads/2015/05/Importance-of-light-in-Photography-1.jpg',
      },
      {
        shop_name: 'Puma',
        shop_id: '3',
        shop_url:
          'https://images.pexels.com/photos/1124960/pexels-photo-1124960.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
      },
      {
        shop_name: 'Nike',
        shop_id: '1',
        shop_url:
          'https://media.istockphoto.com/photos/string-light-bulbs-at-sunset-picture-id1300384615?k=20&m=1300384615&s=170667a&w=0&h=w-Su3d7pPUcjMVvFz2b_tYhs3SGtsSbjaTUzlooEByw=',
      },
      {
        shop_name: 'Adidas',
        shop_id: '2',
        shop_url:
          'https://blog-assets.shawacademy.com/uploads/2015/05/Importance-of-light-in-Photography-1.jpg',
      },
      {
        shop_name: 'Puma',
        shop_id: '3',
        shop_url:
          'https://images.pexels.com/photos/1124960/pexels-photo-1124960.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
      },
      {
        shop_name: 'Under Armour',
        shop_id: '4',
        shop_url:
          'https://denverzoo.org/wp-content/uploads/2018/10/zoolights5.jpg',
      },
      {
        shop_name: 'Nike',
        shop_id: '1',
        shop_url:
          'https://media.istockphoto.com/photos/string-light-bulbs-at-sunset-picture-id1300384615?k=20&m=1300384615&s=170667a&w=0&h=w-Su3d7pPUcjMVvFz2b_tYhs3SGtsSbjaTUzlooEByw=',
      },
      {
        shop_name: 'Adidas',
        shop_id: '2',
        shop_url:
          'https://blog-assets.shawacademy.com/uploads/2015/05/Importance-of-light-in-Photography-1.jpg',
      },
      {
        shop_name: 'Puma',
        shop_id: '3',
        shop_url:
          'https://images.pexels.com/photos/1124960/pexels-photo-1124960.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
      },
      {
        shop_name: 'Nike',
        shop_id: '1',
        shop_url:
          'https://media.istockphoto.com/photos/string-light-bulbs-at-sunset-picture-id1300384615?k=20&m=1300384615&s=170667a&w=0&h=w-Su3d7pPUcjMVvFz2b_tYhs3SGtsSbjaTUzlooEByw=',
      },
      {
        shop_name: 'Adidas',
        shop_id: '2',
        shop_url:
          'https://blog-assets.shawacademy.com/uploads/2015/05/Importance-of-light-in-Photography-1.jpg',
      },
      {
        shop_name: 'Puma',
        shop_id: '3',
        shop_url:
          'https://images.pexels.com/photos/1124960/pexels-photo-1124960.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
      },
      {
        shop_name: 'Under Armour',
        shop_id: '4',
        shop_url:
          'https://denverzoo.org/wp-content/uploads/2018/10/zoolights5.jpg',
      },
      {
        shop_name: 'Nike',
        shop_id: '1',
        shop_url:
          'https://media.istockphoto.com/photos/string-light-bulbs-at-sunset-picture-id1300384615?k=20&m=1300384615&s=170667a&w=0&h=w-Su3d7pPUcjMVvFz2b_tYhs3SGtsSbjaTUzlooEByw=',
      },
      {
        shop_name: 'Adidas',
        shop_id: '2',
        shop_url:
          'https://blog-assets.shawacademy.com/uploads/2015/05/Importance-of-light-in-Photography-1.jpg',
      },
    ],
  },
  {
    id: 3,
    name: 'სამკაულები / Jewellery',
    img: Jewellery,
    count: '54',
    shops: [
      {
        shop_name: 'Adidas',
        shop_id: '2',
        shop_url:
          'https://blog-assets.shawacademy.com/uploads/2015/05/Importance-of-light-in-Photography-1.jpg',
      },
      {
        shop_name: 'Puma',
        shop_id: '3',
        shop_url:
          'https://images.pexels.com/photos/1124960/pexels-photo-1124960.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
      },
      {
        shop_name: 'Under Armour',
        shop_id: '4',
        shop_url:
          'https://denverzoo.org/wp-content/uploads/2018/10/zoolights5.jpg',
      },
      {
        shop_name: 'Nike',
        shop_id: '1',
        shop_url:
          'https://media.istockphoto.com/photos/string-light-bulbs-at-sunset-picture-id1300384615?k=20&m=1300384615&s=170667a&w=0&h=w-Su3d7pPUcjMVvFz2b_tYhs3SGtsSbjaTUzlooEByw=',
      },
      {
        shop_name: 'Adidas',
        shop_id: '2',
        shop_url:
          'https://blog-assets.shawacademy.com/uploads/2015/05/Importance-of-light-in-Photography-1.jpg',
      },
      {
        shop_name: 'Puma',
        shop_id: '3',
        shop_url:
          'https://images.pexels.com/photos/1124960/pexels-photo-1124960.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
      },
      {
        shop_name: 'Nike',
        shop_id: '1',
        shop_url:
          'https://media.istockphoto.com/photos/string-light-bulbs-at-sunset-picture-id1300384615?k=20&m=1300384615&s=170667a&w=0&h=w-Su3d7pPUcjMVvFz2b_tYhs3SGtsSbjaTUzlooEByw=',
      },
      {
        shop_name: 'Adidas',
        shop_id: '2',
        shop_url:
          'https://blog-assets.shawacademy.com/uploads/2015/05/Importance-of-light-in-Photography-1.jpg',
      },
    ],
  },
  {
    id: 4,
    name: 'სპორტული ტანსაცმელი / Sportswear',
    img: Sportwear,
    count: '95',
    shops: [
      {
        shop_name: 'Adidas',
        shop_id: '2',
        shop_url:
          'https://blog-assets.shawacademy.com/uploads/2015/05/Importance-of-light-in-Photography-1.jpg',
      },
      {
        shop_name: 'Puma',
        shop_id: '3',
        shop_url:
          'https://images.pexels.com/photos/1124960/pexels-photo-1124960.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
      },
      {
        shop_name: 'Under Armour',
        shop_id: '4',
        shop_url:
          'https://denverzoo.org/wp-content/uploads/2018/10/zoolights5.jpg',
      },
      {
        shop_name: 'Nike',
        shop_id: '1',
        shop_url:
          'https://media.istockphoto.com/photos/string-light-bulbs-at-sunset-picture-id1300384615?k=20&m=1300384615&s=170667a&w=0&h=w-Su3d7pPUcjMVvFz2b_tYhs3SGtsSbjaTUzlooEByw=',
      },
      {
        shop_name: 'Adidas',
        shop_id: '2',
        shop_url:
          'https://blog-assets.shawacademy.com/uploads/2015/05/Importance-of-light-in-Photography-1.jpg',
      },
      {
        shop_name: 'Puma',
        shop_id: '3',
        shop_url:
          'https://images.pexels.com/photos/1124960/pexels-photo-1124960.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
      },
      {
        shop_name: 'Nike',
        shop_id: '1',
        shop_url:
          'https://media.istockphoto.com/photos/string-light-bulbs-at-sunset-picture-id1300384615?k=20&m=1300384615&s=170667a&w=0&h=w-Su3d7pPUcjMVvFz2b_tYhs3SGtsSbjaTUzlooEByw=',
      },
      {
        shop_name: 'Adidas',
        shop_id: '2',
        shop_url:
          'https://blog-assets.shawacademy.com/uploads/2015/05/Importance-of-light-in-Photography-1.jpg',
      },
      {
        shop_name: 'Puma',
        shop_id: '3',
        shop_url:
          'https://images.pexels.com/photos/1124960/pexels-photo-1124960.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
      },
      {
        shop_name: 'Under Armour',
        shop_id: '4',
        shop_url:
          'https://denverzoo.org/wp-content/uploads/2018/10/zoolights5.jpg',
      },
      {
        shop_name: 'Nike',
        shop_id: '1',
        shop_url:
          'https://media.istockphoto.com/photos/string-light-bulbs-at-sunset-picture-id1300384615?k=20&m=1300384615&s=170667a&w=0&h=w-Su3d7pPUcjMVvFz2b_tYhs3SGtsSbjaTUzlooEByw=',
      },
    ],
  },
  {
    id: 5,
    name: 'თეთრეული / Lingerie',
    img: Lingerie,
    count: '11',
    shops: [
      {
        shop_name: 'Adidas',
        shop_id: '2',
        shop_url:
          'https://blog-assets.shawacademy.com/uploads/2015/05/Importance-of-light-in-Photography-1.jpg',
      },
      {
        shop_name: 'Puma',
        shop_id: '3',
        shop_url:
          'https://images.pexels.com/photos/1124960/pexels-photo-1124960.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
      },
      {
        shop_name: 'Under Armour',
        shop_id: '4',
        shop_url:
          'https://denverzoo.org/wp-content/uploads/2018/10/zoolights5.jpg',
      },
      {
        shop_name: 'Nike',
        shop_id: '1',
        shop_url:
          'https://media.istockphoto.com/photos/string-light-bulbs-at-sunset-picture-id1300384615?k=20&m=1300384615&s=170667a&w=0&h=w-Su3d7pPUcjMVvFz2b_tYhs3SGtsSbjaTUzlooEByw=',
      },
      {
        shop_name: 'Adidas',
        shop_id: '2',
        shop_url:
          'https://blog-assets.shawacademy.com/uploads/2015/05/Importance-of-light-in-Photography-1.jpg',
      },
      {
        shop_name: 'Puma',
        shop_id: '3',
        shop_url:
          'https://images.pexels.com/photos/1124960/pexels-photo-1124960.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
      },
      {
        shop_name: 'Nike',
        shop_id: '1',
        shop_url:
          'https://media.istockphoto.com/photos/string-light-bulbs-at-sunset-picture-id1300384615?k=20&m=1300384615&s=170667a&w=0&h=w-Su3d7pPUcjMVvFz2b_tYhs3SGtsSbjaTUzlooEByw=',
      },
      {
        shop_name: 'Adidas',
        shop_id: '2',
        shop_url:
          'https://blog-assets.shawacademy.com/uploads/2015/05/Importance-of-light-in-Photography-1.jpg',
      },
      {
        shop_name: 'Puma',
        shop_id: '3',
        shop_url:
          'https://images.pexels.com/photos/1124960/pexels-photo-1124960.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
      },
      {
        shop_name: 'Under Armour',
        shop_id: '4',
        shop_url:
          'https://denverzoo.org/wp-content/uploads/2018/10/zoolights5.jpg',
      },
      {
        shop_name: 'Nike',
        shop_id: '1',
        shop_url:
          'https://media.istockphoto.com/photos/string-light-bulbs-at-sunset-picture-id1300384615?k=20&m=1300384615&s=170667a&w=0&h=w-Su3d7pPUcjMVvFz2b_tYhs3SGtsSbjaTUzlooEByw=',
      },
    ],
  },
  {
    id: 6,
    name: 'სასაჩუქრე / Gifts',
    img: Gift2,
    count: '82',
    shops: [
      {
        shop_name: 'Adidas',
        shop_id: '2',
        shop_url:
          'https://blog-assets.shawacademy.com/uploads/2015/05/Importance-of-light-in-Photography-1.jpg',
      },
      {
        shop_name: 'Puma',
        shop_id: '3',
        shop_url:
          'https://images.pexels.com/photos/1124960/pexels-photo-1124960.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
      },
      {
        shop_name: 'Under Armour',
        shop_id: '4',
        shop_url:
          'https://denverzoo.org/wp-content/uploads/2018/10/zoolights5.jpg',
      },
      {
        shop_name: 'Nike',
        shop_id: '1',
        shop_url:
          'https://media.istockphoto.com/photos/string-light-bulbs-at-sunset-picture-id1300384615?k=20&m=1300384615&s=170667a&w=0&h=w-Su3d7pPUcjMVvFz2b_tYhs3SGtsSbjaTUzlooEByw=',
      },
      {
        shop_name: 'Adidas',
        shop_id: '2',
        shop_url:
          'https://blog-assets.shawacademy.com/uploads/2015/05/Importance-of-light-in-Photography-1.jpg',
      },
      {
        shop_name: 'Puma',
        shop_id: '3',
        shop_url:
          'https://images.pexels.com/photos/1124960/pexels-photo-1124960.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
      },
      {
        shop_name: 'Nike',
        shop_id: '1',
        shop_url:
          'https://media.istockphoto.com/photos/string-light-bulbs-at-sunset-picture-id1300384615?k=20&m=1300384615&s=170667a&w=0&h=w-Su3d7pPUcjMVvFz2b_tYhs3SGtsSbjaTUzlooEByw=',
      },
      {
        shop_name: 'Adidas',
        shop_id: '2',
        shop_url:
          'https://blog-assets.shawacademy.com/uploads/2015/05/Importance-of-light-in-Photography-1.jpg',
      },
      {
        shop_name: 'Puma',
        shop_id: '3',
        shop_url:
          'https://images.pexels.com/photos/1124960/pexels-photo-1124960.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
      },
      {
        shop_name: 'Under Armour',
        shop_id: '4',
        shop_url:
          'https://denverzoo.org/wp-content/uploads/2018/10/zoolights5.jpg',
      },
      {
        shop_name: 'Nike',
        shop_id: '1',
        shop_url:
          'https://media.istockphoto.com/photos/string-light-bulbs-at-sunset-picture-id1300384615?k=20&m=1300384615&s=170667a&w=0&h=w-Su3d7pPUcjMVvFz2b_tYhs3SGtsSbjaTUzlooEByw=',
      },
    ],
  },
  {
    id: 7,
    name: 'ვინტაჟური / Vintage',
    img: SecondHand,
    count: '77',
    shops: [
      {
        shop_name: 'Adidas',
        shop_id: '2',
        shop_url:
          'https://blog-assets.shawacademy.com/uploads/2015/05/Importance-of-light-in-Photography-1.jpg',
      },
      {
        shop_name: 'Puma',
        shop_id: '3',
        shop_url:
          'https://images.pexels.com/photos/1124960/pexels-photo-1124960.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
      },
      {
        shop_name: 'Under Armour',
        shop_id: '4',
        shop_url:
          'https://denverzoo.org/wp-content/uploads/2018/10/zoolights5.jpg',
      },
      {
        shop_name: 'Nike',
        shop_id: '1',
        shop_url:
          'https://media.istockphoto.com/photos/string-light-bulbs-at-sunset-picture-id1300384615?k=20&m=1300384615&s=170667a&w=0&h=w-Su3d7pPUcjMVvFz2b_tYhs3SGtsSbjaTUzlooEByw=',
      },
      {
        shop_name: 'Adidas',
        shop_id: '2',
        shop_url:
          'https://blog-assets.shawacademy.com/uploads/2015/05/Importance-of-light-in-Photography-1.jpg',
      },
      {
        shop_name: 'Puma',
        shop_id: '3',
        shop_url:
          'https://images.pexels.com/photos/1124960/pexels-photo-1124960.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
      },
      {
        shop_name: 'Nike',
        shop_id: '1',
        shop_url:
          'https://media.istockphoto.com/photos/string-light-bulbs-at-sunset-picture-id1300384615?k=20&m=1300384615&s=170667a&w=0&h=w-Su3d7pPUcjMVvFz2b_tYhs3SGtsSbjaTUzlooEByw=',
      },
      {
        shop_name: 'Adidas',
        shop_id: '2',
        shop_url:
          'https://blog-assets.shawacademy.com/uploads/2015/05/Importance-of-light-in-Photography-1.jpg',
      },
      {
        shop_name: 'Puma',
        shop_id: '3',
        shop_url:
          'https://images.pexels.com/photos/1124960/pexels-photo-1124960.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
      },
      {
        shop_name: 'Under Armour',
        shop_id: '4',
        shop_url:
          'https://denverzoo.org/wp-content/uploads/2018/10/zoolights5.jpg',
      },
      {
        shop_name: 'Nike',
        shop_id: '1',
        shop_url:
          'https://media.istockphoto.com/photos/string-light-bulbs-at-sunset-picture-id1300384615?k=20&m=1300384615&s=170667a&w=0&h=w-Su3d7pPUcjMVvFz2b_tYhs3SGtsSbjaTUzlooEByw=',
      },
    ],
  },
  {
    id: 8,
    name: 'ჩანთები / Bags',
    img: Bags,
    count: '77',
    shops: [
      {
        shop_name: 'Adidas',
        shop_id: '2',
        shop_url:
          'https://blog-assets.shawacademy.com/uploads/2015/05/Importance-of-light-in-Photography-1.jpg',
      },
      {
        shop_name: 'Puma',
        shop_id: '3',
        shop_url:
          'https://images.pexels.com/photos/1124960/pexels-photo-1124960.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
      },
      {
        shop_name: 'Under Armour',
        shop_id: '4',
        shop_url:
          'https://denverzoo.org/wp-content/uploads/2018/10/zoolights5.jpg',
      },
      {
        shop_name: 'Nike',
        shop_id: '1',
        shop_url:
          'https://media.istockphoto.com/photos/string-light-bulbs-at-sunset-picture-id1300384615?k=20&m=1300384615&s=170667a&w=0&h=w-Su3d7pPUcjMVvFz2b_tYhs3SGtsSbjaTUzlooEByw=',
      },
      {
        shop_name: 'Adidas',
        shop_id: '2',
        shop_url:
          'https://blog-assets.shawacademy.com/uploads/2015/05/Importance-of-light-in-Photography-1.jpg',
      },
      {
        shop_name: 'Puma',
        shop_id: '3',
        shop_url:
          'https://images.pexels.com/photos/1124960/pexels-photo-1124960.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
      },
      {
        shop_name: 'Nike',
        shop_id: '1',
        shop_url:
          'https://media.istockphoto.com/photos/string-light-bulbs-at-sunset-picture-id1300384615?k=20&m=1300384615&s=170667a&w=0&h=w-Su3d7pPUcjMVvFz2b_tYhs3SGtsSbjaTUzlooEByw=',
      },
      {
        shop_name: 'Adidas',
        shop_id: '2',
        shop_url:
          'https://blog-assets.shawacademy.com/uploads/2015/05/Importance-of-light-in-Photography-1.jpg',
      },
      {
        shop_name: 'Puma',
        shop_id: '3',
        shop_url:
          'https://images.pexels.com/photos/1124960/pexels-photo-1124960.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
      },
      {
        shop_name: 'Under Armour',
        shop_id: '4',
        shop_url:
          'https://denverzoo.org/wp-content/uploads/2018/10/zoolights5.jpg',
      },
      {
        shop_name: 'Nike',
        shop_id: '1',
        shop_url:
          'https://media.istockphoto.com/photos/string-light-bulbs-at-sunset-picture-id1300384615?k=20&m=1300384615&s=170667a&w=0&h=w-Su3d7pPUcjMVvFz2b_tYhs3SGtsSbjaTUzlooEByw=',
      },
    ],
  },
  {
    id: 9,
    name: 'ბავშვები / Kids',
    img: Kids,
    count: '77',
    shops: [
      {
        shop_name: 'Adidas',
        shop_id: '2',
        shop_url:
          'https://blog-assets.shawacademy.com/uploads/2015/05/Importance-of-light-in-Photography-1.jpg',
      },
      {
        shop_name: 'Puma',
        shop_id: '3',
        shop_url:
          'https://images.pexels.com/photos/1124960/pexels-photo-1124960.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
      },
      {
        shop_name: 'Under Armour',
        shop_id: '4',
        shop_url:
          'https://denverzoo.org/wp-content/uploads/2018/10/zoolights5.jpg',
      },
      {
        shop_name: 'Nike',
        shop_id: '1',
        shop_url:
          'https://media.istockphoto.com/photos/string-light-bulbs-at-sunset-picture-id1300384615?k=20&m=1300384615&s=170667a&w=0&h=w-Su3d7pPUcjMVvFz2b_tYhs3SGtsSbjaTUzlooEByw=',
      },
      {
        shop_name: 'Adidas',
        shop_id: '2',
        shop_url:
          'https://blog-assets.shawacademy.com/uploads/2015/05/Importance-of-light-in-Photography-1.jpg',
      },
      {
        shop_name: 'Puma',
        shop_id: '3',
        shop_url:
          'https://images.pexels.com/photos/1124960/pexels-photo-1124960.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
      },
      {
        shop_name: 'Nike',
        shop_id: '1',
        shop_url:
          'https://media.istockphoto.com/photos/string-light-bulbs-at-sunset-picture-id1300384615?k=20&m=1300384615&s=170667a&w=0&h=w-Su3d7pPUcjMVvFz2b_tYhs3SGtsSbjaTUzlooEByw=',
      },
      {
        shop_name: 'Adidas',
        shop_id: '2',
        shop_url:
          'https://blog-assets.shawacademy.com/uploads/2015/05/Importance-of-light-in-Photography-1.jpg',
      },
      {
        shop_name: 'Puma',
        shop_id: '3',
        shop_url:
          'https://images.pexels.com/photos/1124960/pexels-photo-1124960.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
      },
      {
        shop_name: 'Under Armour',
        shop_id: '4',
        shop_url:
          'https://denverzoo.org/wp-content/uploads/2018/10/zoolights5.jpg',
      },
      {
        shop_name: 'Nike',
        shop_id: '1',
        shop_url:
          'https://media.istockphoto.com/photos/string-light-bulbs-at-sunset-picture-id1300384615?k=20&m=1300384615&s=170667a&w=0&h=w-Su3d7pPUcjMVvFz2b_tYhs3SGtsSbjaTUzlooEByw=',
      },
    ],
  },
  {
    id: 10,
    name: 'ფეხსაცმელი / Shoes',
    img: Shoes,
    count: '77',
    shops: [
      {
        shop_name: 'Adidas',
        shop_id: '2',
        shop_url:
          'https://blog-assets.shawacademy.com/uploads/2015/05/Importance-of-light-in-Photography-1.jpg',
      },
      {
        shop_name: 'Puma',
        shop_id: '3',
        shop_url:
          'https://images.pexels.com/photos/1124960/pexels-photo-1124960.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
      },
      {
        shop_name: 'Under Armour',
        shop_id: '4',
        shop_url:
          'https://denverzoo.org/wp-content/uploads/2018/10/zoolights5.jpg',
      },
      {
        shop_name: 'Nike',
        shop_id: '1',
        shop_url:
          'https://media.istockphoto.com/photos/string-light-bulbs-at-sunset-picture-id1300384615?k=20&m=1300384615&s=170667a&w=0&h=w-Su3d7pPUcjMVvFz2b_tYhs3SGtsSbjaTUzlooEByw=',
      },
      {
        shop_name: 'Adidas',
        shop_id: '2',
        shop_url:
          'https://blog-assets.shawacademy.com/uploads/2015/05/Importance-of-light-in-Photography-1.jpg',
      },
      {
        shop_name: 'Puma',
        shop_id: '3',
        shop_url:
          'https://images.pexels.com/photos/1124960/pexels-photo-1124960.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
      },
      {
        shop_name: 'Nike',
        shop_id: '1',
        shop_url:
          'https://media.istockphoto.com/photos/string-light-bulbs-at-sunset-picture-id1300384615?k=20&m=1300384615&s=170667a&w=0&h=w-Su3d7pPUcjMVvFz2b_tYhs3SGtsSbjaTUzlooEByw=',
      },
      {
        shop_name: 'Adidas',
        shop_id: '2',
        shop_url:
          'https://blog-assets.shawacademy.com/uploads/2015/05/Importance-of-light-in-Photography-1.jpg',
      },
      {
        shop_name: 'Puma',
        shop_id: '3',
        shop_url:
          'https://images.pexels.com/photos/1124960/pexels-photo-1124960.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
      },
      {
        shop_name: 'Under Armour',
        shop_id: '4',
        shop_url:
          'https://denverzoo.org/wp-content/uploads/2018/10/zoolights5.jpg',
      },
      {
        shop_name: 'Nike',
        shop_id: '1',
        shop_url:
          'https://media.istockphoto.com/photos/string-light-bulbs-at-sunset-picture-id1300384615?k=20&m=1300384615&s=170667a&w=0&h=w-Su3d7pPUcjMVvFz2b_tYhs3SGtsSbjaTUzlooEByw=',
      },
    ],
  },
  {
    id: 11,
    name: 'გამოწერით / To Order',
    img: Arrival,
    count: '77',
    shops: [
      {
        shop_name: 'Adidas',
        shop_id: '2',
        shop_url:
          'https://blog-assets.shawacademy.com/uploads/2015/05/Importance-of-light-in-Photography-1.jpg',
      },
      {
        shop_name: 'Puma',
        shop_id: '3',
        shop_url:
          'https://images.pexels.com/photos/1124960/pexels-photo-1124960.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
      },
      {
        shop_name: 'Under Armour',
        shop_id: '4',
        shop_url:
          'https://denverzoo.org/wp-content/uploads/2018/10/zoolights5.jpg',
      },
      {
        shop_name: 'Nike',
        shop_id: '1',
        shop_url:
          'https://media.istockphoto.com/photos/string-light-bulbs-at-sunset-picture-id1300384615?k=20&m=1300384615&s=170667a&w=0&h=w-Su3d7pPUcjMVvFz2b_tYhs3SGtsSbjaTUzlooEByw=',
      },
      {
        shop_name: 'Adidas',
        shop_id: '2',
        shop_url:
          'https://blog-assets.shawacademy.com/uploads/2015/05/Importance-of-light-in-Photography-1.jpg',
      },
      {
        shop_name: 'Puma',
        shop_id: '3',
        shop_url:
          'https://images.pexels.com/photos/1124960/pexels-photo-1124960.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
      },
      {
        shop_name: 'Nike',
        shop_id: '1',
        shop_url:
          'https://media.istockphoto.com/photos/string-light-bulbs-at-sunset-picture-id1300384615?k=20&m=1300384615&s=170667a&w=0&h=w-Su3d7pPUcjMVvFz2b_tYhs3SGtsSbjaTUzlooEByw=',
      },
      {
        shop_name: 'Adidas',
        shop_id: '2',
        shop_url:
          'https://blog-assets.shawacademy.com/uploads/2015/05/Importance-of-light-in-Photography-1.jpg',
      },
      {
        shop_name: 'Puma',
        shop_id: '3',
        shop_url:
          'https://images.pexels.com/photos/1124960/pexels-photo-1124960.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
      },
      {
        shop_name: 'Under Armour',
        shop_id: '4',
        shop_url:
          'https://denverzoo.org/wp-content/uploads/2018/10/zoolights5.jpg',
      },
      {
        shop_name: 'Nike',
        shop_id: '1',
        shop_url:
          'https://media.istockphoto.com/photos/string-light-bulbs-at-sunset-picture-id1300384615?k=20&m=1300384615&s=170667a&w=0&h=w-Su3d7pPUcjMVvFz2b_tYhs3SGtsSbjaTUzlooEByw=',
      },
    ],
  },
  {
    id: 12,
    name: 'საზაფხულო / Summer',
    img: Summer,
    count: '77',
    shops: [
      {
        shop_name: 'Adidas',
        shop_id: '2',
        shop_url:
          'https://blog-assets.shawacademy.com/uploads/2015/05/Importance-of-light-in-Photography-1.jpg',
      },
      {
        shop_name: 'Puma',
        shop_id: '3',
        shop_url:
          'https://images.pexels.com/photos/1124960/pexels-photo-1124960.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
      },
      {
        shop_name: 'Under Armour',
        shop_id: '4',
        shop_url:
          'https://denverzoo.org/wp-content/uploads/2018/10/zoolights5.jpg',
      },
      {
        shop_name: 'Nike',
        shop_id: '1',
        shop_url:
          'https://media.istockphoto.com/photos/string-light-bulbs-at-sunset-picture-id1300384615?k=20&m=1300384615&s=170667a&w=0&h=w-Su3d7pPUcjMVvFz2b_tYhs3SGtsSbjaTUzlooEByw=',
      },
      {
        shop_name: 'Adidas',
        shop_id: '2',
        shop_url:
          'https://blog-assets.shawacademy.com/uploads/2015/05/Importance-of-light-in-Photography-1.jpg',
      },
      {
        shop_name: 'Puma',
        shop_id: '3',
        shop_url:
          'https://images.pexels.com/photos/1124960/pexels-photo-1124960.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
      },
      {
        shop_name: 'Nike',
        shop_id: '1',
        shop_url:
          'https://media.istockphoto.com/photos/string-light-bulbs-at-sunset-picture-id1300384615?k=20&m=1300384615&s=170667a&w=0&h=w-Su3d7pPUcjMVvFz2b_tYhs3SGtsSbjaTUzlooEByw=',
      },
      {
        shop_name: 'Adidas',
        shop_id: '2',
        shop_url:
          'https://blog-assets.shawacademy.com/uploads/2015/05/Importance-of-light-in-Photography-1.jpg',
      },
      {
        shop_name: 'Puma',
        shop_id: '3',
        shop_url:
          'https://images.pexels.com/photos/1124960/pexels-photo-1124960.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
      },
      {
        shop_name: 'Under Armour',
        shop_id: '4',
        shop_url:
          'https://denverzoo.org/wp-content/uploads/2018/10/zoolights5.jpg',
      },
      {
        shop_name: 'Nike',
        shop_id: '1',
        shop_url:
          'https://media.istockphoto.com/photos/string-light-bulbs-at-sunset-picture-id1300384615?k=20&m=1300384615&s=170667a&w=0&h=w-Su3d7pPUcjMVvFz2b_tYhs3SGtsSbjaTUzlooEByw=',
      },
    ],
  },
  {
    id: 13,
    name: 'საათები / Watches',
    img: Watch,
    count: '77',
    shops: [
      {
        shop_name: 'Adidas',
        shop_id: '2',
        shop_url:
          'https://blog-assets.shawacademy.com/uploads/2015/05/Importance-of-light-in-Photography-1.jpg',
      },
      {
        shop_name: 'Puma',
        shop_id: '3',
        shop_url:
          'https://images.pexels.com/photos/1124960/pexels-photo-1124960.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
      },
      {
        shop_name: 'Under Armour',
        shop_id: '4',
        shop_url:
          'https://denverzoo.org/wp-content/uploads/2018/10/zoolights5.jpg',
      },
      {
        shop_name: 'Nike',
        shop_id: '1',
        shop_url:
          'https://media.istockphoto.com/photos/string-light-bulbs-at-sunset-picture-id1300384615?k=20&m=1300384615&s=170667a&w=0&h=w-Su3d7pPUcjMVvFz2b_tYhs3SGtsSbjaTUzlooEByw=',
      },
      {
        shop_name: 'Adidas',
        shop_id: '2',
        shop_url:
          'https://blog-assets.shawacademy.com/uploads/2015/05/Importance-of-light-in-Photography-1.jpg',
      },
      {
        shop_name: 'Puma',
        shop_id: '3',
        shop_url:
          'https://images.pexels.com/photos/1124960/pexels-photo-1124960.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
      },
      {
        shop_name: 'Nike',
        shop_id: '1',
        shop_url:
          'https://media.istockphoto.com/photos/string-light-bulbs-at-sunset-picture-id1300384615?k=20&m=1300384615&s=170667a&w=0&h=w-Su3d7pPUcjMVvFz2b_tYhs3SGtsSbjaTUzlooEByw=',
      },
      {
        shop_name: 'Adidas',
        shop_id: '2',
        shop_url:
          'https://blog-assets.shawacademy.com/uploads/2015/05/Importance-of-light-in-Photography-1.jpg',
      },
      {
        shop_name: 'Puma',
        shop_id: '3',
        shop_url:
          'https://images.pexels.com/photos/1124960/pexels-photo-1124960.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
      },
      {
        shop_name: 'Under Armour',
        shop_id: '4',
        shop_url:
          'https://denverzoo.org/wp-content/uploads/2018/10/zoolights5.jpg',
      },
      {
        shop_name: 'Nike',
        shop_id: '1',
        shop_url:
          'https://media.istockphoto.com/photos/string-light-bulbs-at-sunset-picture-id1300384615?k=20&m=1300384615&s=170667a&w=0&h=w-Su3d7pPUcjMVvFz2b_tYhs3SGtsSbjaTUzlooEByw=',
      },
    ],
  },
]
