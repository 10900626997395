import { Categories } from 'database/categories'

export const GET_CATEGORY_BY_ID = 'GET_CATEGORY_BY_ID'

export const initialState: any = {
  category: {},
  categories: Categories,
}

const reducer = (state, action) => {
  switch (action.type) {
    case GET_CATEGORY_BY_ID: {
      return {
        ...state,
        category: state?.categories?.find(
          (item) => Number(item.id) === Number(action.payload)
        ),
      }
    }
    default:
      break
  }

  return state
}

export default reducer
