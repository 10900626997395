import { useEffect, useState } from 'react';
import CIcon from 'components/Icon/Icon';
import { Link } from 'react-router-dom';
import Logo from '../../assets/kkk.png';
import st from './Header.module.scss';
import cx from 'classnames';


const Header = () => {
    const [headerSticky, setHeaderSticky] = useState(false)

    useEffect(() => {
        function handleScroll() {
            if (window.pageYOffset > 80) {
                setHeaderSticky(true);
            } if (window.pageYOffset === 0) {
                setHeaderSticky(false);
            }
        }
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [headerSticky, window.pageYOffset]);

    return (
        <div className={cx(st.headerContainer, { [st.headerSticky]: headerSticky })}>
            <div className={cx(st.header)}>
                <Link to='/en'><img onClick={() => window.scrollTo(0, 0)} src={Logo} alt='' /></Link>
                <div className={st.moto}>
                    <CIcon filename='instagram-text' />
                    {/* <div className={st.motoText}>შოპინგი მარტივად!</div> */}
                    <div className={st.motoText}>Shopping Made Easy!</div>
                </div>
                <div className={st.socialContainer}>
                    <div className={st.facebookBox}>
                        <a href='https://www.instagram.com/instashop.ge/' target='_blank' rel="noreferrer"><CIcon filename='facebook' /></a>
                    </div>
                    <div className={st.instagramBox}>
                        <a href='https://www.instagram.com/instashop.ge/' target='_blank' rel="noreferrer"><CIcon filename='instagram' /></a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header;
